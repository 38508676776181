<template>
  <div class="users-add-container-bulk-load">
    <div class="download">
      <a href="/administrador/files/plantilla_carga_masiva.xlsx" download><button>Descargar Plantilla</button></a>
    </div>
    <div class="bulk-load">
      <div class="bulk-load-container">
        <label for="bulk-load" v-show="loadedFilename == ''">
          <input id="bulk-load" type="file" @change="onFileChange" accept=".xlsx, .xls, .csv" style="display: none" />
          <div class="plus-icon align-items-center">
            <span class="icon">
              <font-awesome-icon icon="plus-circle" />
            </span>
            <span>
              Haz clic aquí para cargar un archivo desde tu computador
            </span>
          </div>
        </label>
        <div class="file-preview" v-if="loadedFilename !== ''">
          <div class="file-name">{{ loadedFilename }}</div>
          <div class="delete-preview">
            <button @click="deletePreviewFile()">
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bulk-load-list" v-if="uploadResponse.length > 0">
      <div class="header">
        <div class="header-nombre">Nombre</div>
        <div class="header-rut">RUT</div>
        <!-- <div class="header-correo">Estado</div> -->
        <div class="header-result">Acción</div>
      </div>
      <div class="boxrow">
        <div class="row" v-for="user in uploadResponse" :key="user.rut">
          <div class="row-nombre">
            <p> {{ `${user.primer_nombre} ${user.apellido_paterno}` }} </p>
          </div>
          <div class="row-rut">
            {{ user.rut_usuario + '-' + user.dv_usuario }}
          </div>
          <!-- <div class="row-correo">
            {{ user.action == "in database" ? 'Existente': user.action == 'field-error' ? 'Error: Campos inválidos': '' }}
          </div> -->
          <div class="row-result">
            <p :class="user.result">
              {{
                user.action == "updated" ? 
                  "Actualizado"
                  : user.action == "field-error" ?
                    "error"
                    : "Creado"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bulk-load-buttons">
      <button class="cancel" @click="closeBulkLoad()">Cancelar</button>
      <button class="create" :class="active_save_btn && 'active_save'" @click="onSubmit()">
        Agregar Usuarios
      </button>
    </div>
    <!-- Modal para mostrar instrucciones de Carga Masiva-->
    <Instructions v-if="false" />
    <!-- Modal para mostrar resultados de Carga Masiva -->

    <!-- Spinner -->
    <Spinner v-if="show_spinner" />

    <!--  Modal que muestra el estado de la carga masiva (exito o error) -->
    <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="closeStatus" />
  </div>
</template>

<script>
import Instructions from "../Modales/Instructions.vue";
import * as XLSX from "xlsx/xlsx.mjs";
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";
import { mapActions } from "vuex";
import ChileanRutify from 'chilean-rutify';
import moment from "moment";

export default {
  components: {
    Instructions,
    Spinner,
    Status,
  },

  data() {
    return {
      requiredFields: [
        "RUT",
        "Correo",
        "Primer_nombre", 
        "Apellido_paterno",
        "Fecha_nacimiento", 
        "Fecha_ingreso", 
        "Cargo",
        "Gerencia", 
        "Sucursal", 
        "Turno",
        "Sociedad",
        "Genero"
      ],
      show_spinner: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: false,
      id_creador: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      active_save_btn: false,
      isDataLoaded: false,
      loadedFilename: "",

      loadedUsers: [],
      emailRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      uploadResponse: []
    };
  },

  methods: {
    ...mapActions("usersModule", [
      "addBulkLoadAction",
      "editBulkLoadAction"
    ]),

    async onFileChange(event) {
      this.show_spinner = true;
      const file = event.target.files[0];
      this.isDataLoaded = await this.readBulkFile(file);
      this.validateUsersData();
      this.showReadResult();

      this.show_spinner = false;
    }, 

    // Almacena archivo CSV-parseable en loadedUsers como array
    readBulkFile(file) {
      this.loadedFilename = file.name; 
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          let csv;
          if (file.type === 'text/csv') {
            csv = e.target.result;
          } else if ( 
            file.type === 'application/vnd.ms-excel' || 
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
          ) {
            const workbook = XLSX.read(e.target.result, { type: 'binary' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            csv = XLSX.utils.sheet_to_csv(worksheet);
          } else {
            alert('Formato inválido. Por favor seleccione un archivo CSV, XLS, o XLSX.');
            return;
          }

          const lines = csv.split('\n');
          const headers = lines[0].split(',');
          for (let i = 1; i < lines.length; i++) {
            const data = lines[i].split(',');
            if (data.every(cellValue => cellValue === "")) {
              break;
            }
            const row = {};
            for (let j = 0; j < headers.length; j++) {
              row[headers[j]] = data[j];
            }
            this.loadedUsers.push({ ...row, errorMessages: []});
          }
          resolve(true);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file); 
      });
    },

    /* 
      Validaciones:
      (i): presencia de campos obligatorios
      (ii): validez de RUT
      (iii): validez de correo
      (iv): validez formato de fechas
    */
    validateUsersData() {
      this.loadedUsers.forEach(user => {
        this.requiredFields.forEach(field => {
          if (!user[field]) user.errorMessages.push(`${field} ausente.`);

          if (field == "RUT" && user[field]) {
            if ( !ChileanRutify.validRut(user.RUT) ) user.errorMessages.push(`RUT inválido: ${user.RUT}.`);
          }

          if (field == "Correo" && user[field]) {
            if ( !this.emailRegex.test(user.Correo) ) user.errorMessages.push(`Correo inválido: ${user.Correo}.`);
          }

          if (field == "Fecha_nacimiento" && user[field]) {
            if (moment(user.Fecha_nacimiento, "DD-MM-YYYY").format("YYYY-MM-DD") === "Invalid date") user.errorMessages.push(`Fecha inválida: ${user.Fecha_nacimiento} (formato permitido: DD-MM-YYYY o DD/MM/YYYY).`);
          }

          if (field == "Fecha_ingreso" && user[field]) {
            if (moment(user.Fecha_ingreso, "DD-MM-YYYY").format("YYYY-MM-DD") === "Invalid date") user.errorMessages.push(`Fecha inválida: ${user.Fecha_ingreso} (formato permitido: DD-MM-YYYY o DD/MM/YYYY).`);
          }
        });
      });
    },

    showReadResult() {
      const fileReadMessages = [];
      for (let i = 0; i < this.loadedUsers.length; i++) {
        if (this.loadedUsers[i].errorMessages.length === 0) continue;
        let rowMessage = `- Fila ${i+2}: ${this.loadedUsers[i].errorMessages.join(" ")}`;
        fileReadMessages.push(rowMessage);        
      }

      if (fileReadMessages.length === 0) {
        this.toastDocumentValid();
      } else {
        // modal con mensaje..
        fileReadMessages.unshift("Problemas con el documento cargado:");
        this.open_modal_status = true;
        this.modal_status_msg = fileReadMessages.join("<br>");
      }
    },

    deletePreviewFile() {
      this.loadedFilename = "";
      this.loadedUsers = [];
      this.isDataLoaded = false;
    },

    getPayload() {
      /* 
        - formatear rut
        - formatear fecha
        - privilegio = "-"
        - grupo = "-"
        - genero = "F"/"M"
        - entity_id = "0"
        - campos opcionales vacíos = "-"
          (menos Segundo_nombre y Apellido_materno)
      */
      const payload = {
        rut_usuario: [],
        dv_usuario: [],
        primer_nombre: [],
        segundo_nombre: [],
        apellido_paterno: [],
        apellido_materno: [],
        correo: [],
        telefono_movil: [],
        anexo: [],
        telefono_fijo: [],
        fecha_nacimiento: [],
        fecha_ingreso: [],
        genero: [],
        entity_id: [],
        cargo: [],
        gerencia: [],
        sucursal: [],
        turno: [],
        nacion: [],
        sociedad: [],
        sindicato: [],
        privilegio: [],
        grupo: [],
        id_creador: this.id_creador,        
        id_empresa_fk: this.id_empresa   
      };

      this.loadedUsers.forEach(user => {
        const [rut_usuario, dv_usuario] = this.getPayloadFormattedRut(user.RUT);
        const genero = user.Genero[0].toUpperCase();
        // console.log(`NACIMIENTO: ${user.Fecha_nacimiento}. INGRESO: ${user.Fecha_ingreso}`);
        const fecha_nacimiento = this.getPayloadFormattedDate(user.Fecha_nacimiento);
        const fecha_ingreso = this.getPayloadFormattedDate(user.Fecha_ingreso);

        const telefono_movil = user.Telefono_movil ? user.Telefono_movil : "-";
        const telefono_fijo = user.Telefono_fijo ? user.Telefono_fijo : "-";
        const anexo = user.Anexo ? user.Anexo : "-";
        const nacion = user.Nacionalidad ? user.Nacionalidad : "-";
        const sindicato = user.Sindicato ? user.Sindicato : "-";

        payload.rut_usuario     .push(rut_usuario);
        payload.dv_usuario      .push(dv_usuario);
        payload.genero          .push(genero);
        payload.primer_nombre   .push(user.Primer_nombre);
        payload.segundo_nombre  .push(user.Segundo_nombre);
        payload.apellido_paterno.push(user.Apellido_paterno);
        payload.apellido_materno.push(user.Apellido_materno);
        payload.correo          .push(user.Correo);
        payload.cargo           .push(user.Cargo);
        payload.gerencia        .push(user.Gerencia);
        payload.sucursal        .push(user.Sucursal);
        payload.turno           .push(user.Turno);
        payload.sociedad        .push(user.Sociedad);
        payload.telefono_fijo   .push(telefono_fijo);
        payload.telefono_movil  .push(telefono_movil);
        payload.anexo           .push(anexo);
        payload.nacion          .push(nacion);
        payload.sindicato       .push(sindicato);
        payload.fecha_nacimiento.push(fecha_nacimiento);
        payload.fecha_ingreso   .push(fecha_ingreso);
        payload.entity_id       .push("0");
        payload.privilegio      .push("-");
        payload.grupo           .push("-");
      });

      return payload;
    },

    getPayloadFormattedDate(date) {
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    },

    getPayloadFormattedRut(rut) {
      const normalizedRut = ChileanRutify.normalizeRut(rut);
      const base = normalizedRut.slice(0, normalizedRut.length-1);
      const dv = normalizedRut[normalizedRut.length-1];
      return [base, dv];
    },

    async onSubmit() {
      // valida si documento está cargado
      if (!this.isDataLoaded) {
        this.toastDocumentLoadNotice();
        return;
      }

      // valida si documento tiene problemas de validez interno
      if (this.loadedUsers.some(user => user.errorMessages.length > 0)) {
        this.toastDocumentInvalid();
        return;
      }

      const payload = this.getPayload();

      this.show_spinner = true;
      this.uploadResponse = await this.editBulkLoadAction(payload);
      if (this.uploadResponse) {
        /* Show load result maybe? */
        this.toastUsersLoadSuccess();
      } else {
        this.toastGenericError();
      }
      this.show_spinner = false;
    },
    
    closeBulkLoad() {
      this.$emit("close");
    },
    closeStatus() {
      this.open_modal_status = false;
      this.active_save_btn = false;
    },

    toastUsersLoadSuccess() {
      this.$toast.open({
        message: 'Los usuarios fueron creados satisfactoriamente.',
        type: 'success',
        duration: 6000,
        position: 'top-right'
      });
    },
    toastDocumentValid() {
      this.$toast.open({
        message: 'Documento cargado correctamente.',
        type: 'success',
        duration: 6000,
        position: 'top-right'
      });
    },
    toastDocumentInvalid() {
      this.$toast.open({
        message: 'El documento presenta errores. Por favor soluciónalos y vuelve a cargar.',
        type: 'error',
        duration: 6000,
        position: 'top-right'
      });
    },
    toastDocumentLoadError() {
      this.$toast.open({
        message:'Ha ocurrido un error al cargar el documento. Por favor inténtalo nuevamente.',
        type:'error',
        duration:6000,
        position:'top-right'
      });
    },
    toastDocumentLoadNotice() {
      this.$toast.open({
        message:'Carga un archivo desde tu computador para continuar.',
        type:'error',
        duration:6000,
        position:'top-right'
      });
    },
    toastGenericError() {
      this.$toast.open({
        message: "Error al enviar los datos. Por favor recarga la página e intenta nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
  }
};
</script>

<style lang="scss" scoped>
$maxHeight: 60vh;

*::v-deep .status-modal-container {
  min-width: 380px;
  min-height: 140px;
  max-width: 800px;
  max-height: $maxHeight;
  width: fit-content;
  height: fit-content;
}

*::v-deep .status-modal-container-message {
  margin: 20px 0 10px 5px;
  font-size: 1rem;
  text-align: initial;
  position: inherit;
  max-width: inherit;
  max-height: $maxHeight - 15vh;
  overflow-y: auto;
}
</style>

