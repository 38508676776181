<template>
  <section id="usuario-form">
    <SectionTabs :tabs="tab_component" />

    <div class="users-add">
      <div class="subtitle">
        <div class="left">
          {{ subtitle }}
        </div>
        <!-- carga masiva -->
        <div v-if="!open_bulk_load">
          <button 
            @click="openbulkLoad()"
            class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
          >
            <font-awesome-icon class="icon pe-2" icon="upload"></font-awesome-icon>
            Carga masiva
          </button>
        </div>
      </div>

      <Loading 
        v-if="loading"
        pixeles="150px"
      />

      <template v-if="!loading">    
        <div class="users-add-container">
          <!-- Formulario Crear Usuario -->
          <FormulateForm
            class="users-add-container-form"
            v-model="userForm"
            v-if="!open_bulk_load"
            @submit="submitUserForm()"
          >
            <!-- Inputs -->
            <div class="form-container form">
  
              <!-- Nombres * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Nombres *</label>
                  <FormulateInput
                    type="text" 
                    name="firstnames" 
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Apellido paterno * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Apellido paterno *</label>
                  <FormulateInput 
                    type="text" 
                    name="lastname1" 
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Apellido materno * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Apellido materno *</label>
                  <FormulateInput 
                    type="text" 
                    name="lastname2" 
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- RUT * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>RUT *</label>
                  <FormulateInput 
                    type="text" 
                    name="rut" 
                    validation="bail|required|validRut"
                    :wrapper-class="setStyleOnInvalid"
                  />
                </div>
              </div>
  
              <!-- Correo -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Correo</label>
                  <FormulateInput 
                    type="text" 
                    name="email" 
                    validation="email"
                    :wrapper-class="setStyleOnInvalid"
                  />
                </div>
              </div>
  
              <!-- Teléfono móvil -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Teléfono móvil</label>
                  <FormulateInput 
                    wrapper-class="input-container"
                    type="text" 
                    name="phone1"
                    v-on:keypress="handleKeyPress($event)"
                  />
                </div>
              </div>
  
              <!-- Teléfono fijo -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Teléfono fijo</label>
                  <FormulateInput 
                    wrapper-class="input-container"
                    type="text" 
                    name="phone2"
                    v-on:keypress="handleKeyPress($event)"
                  />
                </div>
              </div>
  
              <!-- Fecha de nacimiento * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Fecha de nacimiento *</label>
                  <FormulateInput 
                    type="text" 
                    name="birthdate" 
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                    @click="openCalendar('birthdate')"
                    v-model="userForm.birthdate"
                  >
                    <template #suffix>
                      <img class="input-container-img" :src="img_calendar" alt="" />
                    </template>
                  </FormulateInput>
                </div>
              </div>
  
              <!-- Fecha de ingreso * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Fecha de ingreso *</label>
                  <FormulateInput 
                    type="text" 
                    name="entrydate" 
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                    @click="openCalendar('entrydate')"
                    v-model="userForm.entrydate"
                  >
                    <template #suffix>
                      <img class="input-container-img" :src="img_calendar" alt="" />
                    </template>
                  </FormulateInput>
                </div>
              </div>
  
              <!-- Anexo -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Anexo</label>
                  <FormulateInput 
                    wrapper-class="input-container"
                    type="text" 
                    name="anexo"
                  />
                </div>
              </div>
  
              <!-- Cargo * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Cargo *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione cargo"
                    :options="positionArr" 
                    name="position"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Área * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Área *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione área"
                    :options="areaArr" 
                    name="area"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Sucursal * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Sucursal *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione sucursal"
                    :options="workplaceArr" 
                    name="workplace"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Turno * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Turno *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione turno"
                    :options="shiftArr" 
                    name="shift"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Sociedad * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Sociedad *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione sociedad"
                    :options="societyArr" 
                    name="society"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Sindicato * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Sindicato *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione sindicato"
                    :options="syndicateArr" 
                    name="syndicate"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Nacionalidad * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Nacionalidad *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione nacionalidad"
                    :options="nationArr" 
                    name="nation"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Grupo * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Grupo *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione grupo"
                    :options="groupArr" 
                    name="group"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Género * -->
              <div class="form-container__input-elem">
                <div class="elem">
                  <label>Género *</label>
                  <FormulateInput 
                    type="select"
                    placeholder="Seleccione género"
                    :options="[{ value: 'F', label: 'Femenino' }, { value: 'M', label: 'Masculino' }]" 
                    name="gender"
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                  />
                </div>
              </div>
  
              <!-- Fecha de movimiento * -->
              <div class="form-container__input-elem" v-if="changedPosition">
                <div class="elem">
                  <label>Fecha de movimiento *</label>
                  <FormulateInput 
                    type="text" 
                    name="moveorg" 
                    validation="required"
                    :wrapper-class="setStyleOnInvalid"
                    errors-class="d-none"
                    @click="openCalendar('moveorg')"
                    v-model="userForm.moveorg"
                  >
                    <template #suffix>
                      <img class="input-container-img" :src="img_calendar" alt="" />
                    </template>
                  </FormulateInput>
                </div>
              </div>
  
  
              <!-- (CALENDARIOS) -->
              <div class="form-calendar birthdate" v-if="calendarType == 'birthdate'">
                <div class="border-arrow">
                  <div class="arrow"></div>
                </div>
                <v-date-picker
                  locale="es"
                  color="blue"
                  class="date"
                  :min-date="min_date"
                  :max-date="max_date"
                  @dayclick="onDayClick"
                  v-model="calendar_birthdate"
                />
              </div>
              <div class="form-calendar entrydate" v-if="calendarType == 'entrydate'">
                <div class="border-arrow">
                  <div class="arrow"></div>
                </div>
                <v-date-picker
                  locale="es"
                  color="blue"
                  class="date"
                  :min-date="min_date"
                  :max-date="max_date"
                  @dayclick="onDayClick"
                  v-model="calendar_entrydate"
                />
              </div>
              <div class="form-calendar moveorg" v-if="calendarType == 'moveorg'">
                <div class="border-arrow">
                  <div class="arrow"></div>
                </div>
                <v-date-picker
                  locale="es"
                  color="blue"
                  class="date"
                  :min-date="min_date"
                  :max-date="max_date_moveorg"
                  @dayclick="onDayClick"
                  v-model="calendar_moveorg"
                />
              </div>
            </div>
            <div class="form-info" v-if="!open_bulk_load">
              (*) Campo obligatorio
            </div>
            <!-- Submit -->
            <div class="form-container buttons" v-if="!open_bulk_load">
              <div class="left">
                <div
                  class="toggle-switch"
                  :class="toggle_switch_btn && 'active'"
                  @click="handleToggleSwitch"
                >
                  <div class="circle"></div>
                </div>
                <span class="text">
                  Este usuario tiene permisos de
                  <strong> administrador</strong>
                </span>
              </div>
              <div class="right">
                <div class="submit">
                  <button class="clean" @click="goBack()" type="button">
                    {{ editBool ? "Cancelar" : "Volver" }}
                  </button>
                  <FormulateInput 
                    type="submit" 
                    :label="editBool ? 'Guardar' : 'Agregar'" 
                  />
                </div>
              </div>
            </div>
          </FormulateForm>
          <!-- Carga Masiva -->
          <AddBulkLoad
            v-if="open_bulk_load"
            @close="closeBulkLoad"
          />
        </div>
      </template>
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />
      <Question
        v-if="open_question_modal"
        :hideCancel="hide_btn_question"
        :msg="question_modal_msg"
        @cancel="cancelQuestion"
        @accept="acceptQuestion"
      />
    </div>
  </section>
</template>

<script>
  import Status from "../Modales/Status.vue";
  import AddBulkLoad from "./AddBulkLoad.vue";
  import { mapActions, mapState } from "vuex";
  import Question from "../Modales/Question.vue";
  import SectionTabs from "../Section/SectionTabs.vue";
  import ChileanRutify from 'chilean-rutify';

  //FF
  import Loading from "@/components/Loading.vue";

  export default {
    components: {
      Status,
      AddBulkLoad,
      Question,
      SectionTabs,

      //FF
      Loading
    },

    data() {
      return {
        tab_component: [
          {
            name: "Inicio",
            route: "usuarios-list",
          },
          {
            name: "Agregar",
            route: "usuarios-add",
          },
        ],
        subtitle: "Añade a un nuevo colaborador o utiliza la carga masiva.",
        userForm: {},
        max_date: new Date(),
        open_bulk_load: false,
        calendarType: "",
        editBool: false,
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        open_question_modal: false,
        question_modal_msg: "",
        hide_btn_question: false,
        toggle_switch_btn: false,
        active_edit_btn: false,
        min_date: "",
        max_date_moveorg: new Date(),

        calendar_birthdate: new Date(),
        calendar_entrydate: new Date(),
        calendar_moveorg: new Date(),

        old_position: "",
        img_calendar: require("@/assets/img/utilities/calendar.svg"),

        user_id: this.$ls.get("user").id_usuario,
        id_user_edit: 0,

        changedPosition: false,

        //FF
        loading: ''
      };
    },

    computed: {
      ...mapState("usersModule", [
        "positionArr",
        "areaArr",
        "workplaceArr",
        "shiftArr",
        "societyArr",
        "syndicateArr",
        "nationArr",
        "groupArr",
        "privilegesArr",
        "user_edit",
      ]),
      // Funcion del plugin del calendario para destacar el dia seleccionado
      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      },
    },

    watch: {
      toggle_switch_btn() {
        this.getItemsListFor("Grupo", this.getGroupsQuery, this.groupArr);
      },

      userForm: {
        handler(newVal, oldVal) {
          if (this.editBool && (this.user_edit.cargo!=null && this.user_edit.cargo[0]!=null) && (this.user_edit.cargo[0].id_cargo !== newVal.position)) {
            this.changedPosition = true;
          } else {
            this.changedPosition = false;
          }

          if (newVal.rut !== oldVal.rut) {
            this.userForm.rut = ChileanRutify.formatRut(newVal.rut);
          }
        },
        deep: true
      }
    },

    created() {
      this.cleanInputs();
    },

    async mounted() {
      this.loading = true;
      await this.getPrivilegios();

      await Promise.all([
        this.getItemsListFor("Grupo", this.getGroupsQuery),
        this.getItemsListFor("Cargo", this.getPositionsQuery),
        this.getItemsListFor("Área", this.getAreasQuery),
        this.getItemsListFor("Sucursal", this.getSucursalesAction),
        this.getItemsListFor("Sindicato", this.getSyndicatesQuery),
        this.getItemsListFor("Sociedad", this.getSocietiesQuery),
        this.getItemsListFor("Nacionalidad", this.getNationsQuery),
        this.getItemsListFor("Turno", this.getShiftsQuery)
    ]);

      const paramId = this.$route.params.id;
      if (paramId !== undefined && paramId !== null) {
        this.setupEdit(paramId);
      }
      this.loading = false;
    },

    methods: {
      ...mapActions("usersModule", [
        "editUserAction",
        "addUserAction",
        "getPositionsQuery",
        "getAreasQuery",
        "getSucursalesAction",
        "getShiftsQuery",
        "getSocietiesQuery",
        "getSyndicatesQuery",
        "getNationsQuery",
        "getGroupsQuery",
        "getPrivileges",
        "editUserAction",
        "getUserAction",
        "checkEmailRutAction",
        "createUserAction"
      ]),
      async setupEdit(id) {
        this.id_user_edit = id;
        await this.getEditingUserInfo();
        this.setInputsData(this.user_edit);
        this.editBool = true;
        this.open_question_modal = false;
        this.tab_component[1].name = "Editar";
      },
      setStyleOnInvalid(ctx) {
        switch (ctx.type) {
          case "text":          
            if (ctx.hasErrors) return 'input-container invalid-input';
            return 'input-container';
        
          case "select":
            if (ctx.hasErrors) return 'select-container invalid-input';
            return 'select-container';
        }
      },

      async getPrivilegios() {
        const res = await this.getPrivileges();
        if (!res) this.toastGenericError();
      },

      async checkEmailRut(value, type) {
        let formated_value = value;
        if (type == 2) formated_value = value.replace(/[.-]/g, "");
        const data = {
          id_empresa: this.id_empresa,
          objeto: formated_value,
          tipo_objeto: type,
        };
        const response = await this.checkEmailRutAction(data);
        if (response === null) this.toastGenericError(); 
        return response;
      },

      // TODO: factorizar
      async submitUserForm() {
        const payload = this.getPayload();
        let isEmailUnavailable = false;
        let isRutUnavailable = false;

        if (this.editBool) {
          if (this.isRutChanged()) {
            isRutUnavailable = await this.isRutUnavailable(payload.rut_usuario + payload.dv_usuario);
            if (isRutUnavailable) this.toastRutUnavailable();
          }
          if (this.isEmailChanged()) {
            isEmailUnavailable = await this.isEmailUnavailable(payload.correo);
            if (isEmailUnavailable) this.toastEmailUnavailable();
          }
          if (isEmailUnavailable || isRutUnavailable) return;
          await this.editUser(payload);
        } else {

          isRutUnavailable = await this.isRutUnavailable(payload.rut_usuario + payload.dv_usuario);
          isEmailUnavailable = await this.isEmailUnavailable(payload.correo);
          if (isRutUnavailable) this.toastRutUnavailable();
          if (isEmailUnavailable) this.toastEmailUnavailable();

          if (isEmailUnavailable || isRutUnavailable) return;

          await this.createUser(payload);
        }

        this.cleanInputs();

      },



      setInputsData(user) {
        const sindicato = user.sindicato!=null && typeof user.sindicato[0]=== 'object' && user.sindicato[0]!=null? user.sindicato[0].id_sindicato : "-";
        const nacionalidad = user.nacion!=null && typeof user.nacion[0]=== 'object' && user.nacion[0]!=null? user.nacion[0].id_nacion : "-";

        this.userForm.firstnames = user.primer_nombre + " " + user.segundo_nombre;
        this.userForm.lastname1 = user.apellido_paterno;
        this.userForm.lastname2 = user.apellido_materno;
        this.userForm.rut = ChileanRutify.formatRut(user.rut_usuario + user.dv_usuario);
        this.userForm.email = user.correo;
        this.userForm.phone1 = user.telefono_movil;
        this.userForm.phone2 = user.telefono_fijo;
        this.userForm.birthdate = user.fecha_nacimiento.split("-").reverse().join("-");
        this.userForm.entrydate = user.fecha_ingreso.split("-").reverse().join("-");
        this.userForm.anexo = user.anexo;
        this.userForm.position = user.cargo!=null && typeof user.cargo[0]=== 'object' && user.cargo[0]!=null? user.cargo[0].id_cargo : null;
        this.userForm.area = user.gerencia!=null && typeof user.gerencia[0]=== 'object' && user.gerencia[0]!=null? user.gerencia[0].id_gerencia : null;
        this.userForm.workplace = user.sucursal!=null && typeof user.sucursal[0]=== 'object' && user.sucursal[0]!=null? user.sucursal[0].id_sucursal : null;
        this.userForm.shift = user.turno!=null && typeof user.turno[0]=== 'object' && user.turno[0]!=null? user.turno[0].id_turno : null;
        this.userForm.society = user.sociedad!=null && typeof user.sociedad[0]=== 'object' && user.sociedad[0]!=null? user.sociedad[0].id_sociedad : null;
        this.userForm.syndicate = sindicato;
        this.userForm.nation = nacionalidad;
        this.userForm.group = user.grupo!=null && typeof user.grupo[0]=== 'object' && user.grupo[0]!=null? user.grupo[0].id_grupo : null;
        this.userForm.gender = user.genero;
        this.userForm.privilegio = user.privilegio!=null && typeof user.privilegio[0]=== 'object' && user.privilegio[0]!=null? user.privilegio[0].id_tipo : null;
        this.toggle_switch_btn = this.userForm.privilegio  == "3" ? false : true;
      },

      async getItemsListFor(label, queryFunction) {
        const data = { id_empresa: this.id_empresa };
        if (label === "Grupo") {
          // Los usuarios con privilegio "Fundador" no tienen acceso a
          // ver los Grupos (por default), por lo que para obtenerlo le damos
          // temporalmente el privilegio de "Administrador" para que pueda obtenerlos 
          Object.assign(data, { id_tipo_privilegio: this.toggle_switch_btn ? "2" : "3" });
        }
        const res = await queryFunction(data);
        if (!res) {
          this.toastGenericError();
        }
      },

      // Abrir sección carga masiva
      openbulkLoad() {
        this.open_bulk_load = true;
        this.subtitle = "Lee las instrucciones antes de comenzar la carga masiva";
      },
      // Cerrar sección carga masiva
      closeBulkLoad() {
        this.open_bulk_load = false;
        this.subtitle = "(*) Campo obligatorio";
      },

      // Permite ciertos caracteres para inputs de telefono
      handleKeyPress(event) {
        const allowedKeys = [9, 13, 32, 43, 45]; // tab, enter, space, plus sign, dash sign
        const keyCode = event.keyCode;
        
        if ((keyCode >= 48 && keyCode <= 57) || // numbers
            (allowedKeys.includes(keyCode))) { // allowed keys
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      },

      onDayClick(day) {
        this.userForm[this.calendarType] = day.id
          .split("-")
          .reverse()
          .join("-");
        this.calendarType = "";
      },

      // Funcion para abrir alguno de los 3 calendarios
      openCalendar(calendarType) {
        this.calendarType = calendarType;
      },

      async getEditingUserInfo() {
        const data_ = {
          id_usuario: this.id_user_edit,
          id_empresa: this.id_empresa
        };
        const res = await this.getUserAction(data_);
        if (!res) this.toastGenericError();
      },

      async editUser(payload) {
        this.loading = true;
        const res = await this.editUserAction(payload);
        if (res) {        
          this.toastUserEditSuccess();
          this.$router.push({ name: "usuarios-list" });
        } else {
          this.toastUserEditError();  
        }
        this.loading = false;
      },

      async createUser(payload) {
        this.loading = true;
        const user = await this.createUserAction(payload);
        if (user) {        
          if (user.rut_usuario.includes("Existe")) {
            this.open_question_modal = true;
            this.question_modal_msg = "El usuario que intentas agregar ya existe. ¿Deseas editarlo?";
          } else {
            this.toastUserCreateSuccess();
            this.$router.push({ name: "usuarios-list" });
          }
        } else {
          this.modal_status_msg = "Error al crear el usuario. Por favor intenta nuevamente.";
          this.open_modal_status = true;
          this.modal_status = false;
        }
        this.loading = false;
      },

      getPayload() {
        const [ primer_nombre, ...segundo_nombre ] = this.userForm.firstnames.split(" ");
        const data = {
          primer_nombre: primer_nombre,
          segundo_nombre: segundo_nombre.length ? segundo_nombre.join(" ") : "",
          apellido_paterno: this.userForm.lastname1,
          apellido_materno: this.userForm.lastname2,
          rut_usuario: this.userForm.rut.split(".").join("").split("-")[0],
          dv_usuario: this.userForm.rut.split(".").join("").split("-")[1],
          correo: this.userForm.email,
          telefono_movil: this.userForm.phone1 ?? "",
          telefono_fijo: this.userForm.phone2 ?? "",
          fecha_nacimiento: this.userForm.birthdate.split("-").reverse().join("-"),
          fecha_ingreso: this.userForm.entrydate.split("-").reverse().join("-"),
          anexo: this.userForm.anexo ?? "",
          id_cargo_fk: this.userForm.position,
          id_gerencia_fk: this.userForm.area,
          id_sucursal_fk: this.userForm.workplace,
          id_turno_fk: this.userForm.shift,
          id_nacion_fk: this.userForm.nation,
          id_sociedad_fk: this.userForm.society,
          id_sindicato_fk: this.userForm.syndicate,
          id_grupo_fk: this.userForm.group,
          genero: this.userForm.gender,
          id_privilegio_fk: this.toggle_switch_btn ? "2" : "3",
          id_empresa_fk: this.id_empresa,
          entity_id: "0"
        };
        if (this.editBool) {
          Object.assign(data, {
            id_usuario: this.id_user_edit,
            id_modificador: this.user_id,
            fecha_movimiento: this.userForm.moveorg ? 
              this.userForm.moveorg.split("-").reverse().join("-") : "" 
          });
        } else {
          Object.assign(data, {
            id_creador: this.user_id
          });
        }
        return data;
      },

      goBack() {
        this.open_question_modal = true;
        if (this.editBool) {
          this.question_modal_msg =
            "¿Estás seguro que deseas cancelar la edición? Se perderán los cambios realizados.";
        } else {
          this.question_modal_msg =
            "¿Estás seguro que deseas volver? Se perderán los datos ingresados.";
        }
      },

      isRutChanged() {
        return this.userForm.rut !== 
          ChileanRutify.formatRut(this.user_edit.rut_usuario + this.user_edit.dv_usuario);
      },

      isEmailChanged() {
        return this.userForm.email !== this.user_edit.correo;
      },

      async isEmailUnavailable(mail) {
        if (await this.checkEmailRut(mail, 1)) {
          return true;
        } else {
          return false;
        }
      },

      async isRutUnavailable(normalizedRut) {
        if (await this.checkEmailRut(normalizedRut, 2)) {
          return true;
        } else {
          return false;
        }
      },

      cancelQuestion() {
        this.open_question_modal = false;
      },

      acceptQuestion() {
        this.open_question_modal = false;
        this.$router.push({
          name: "usuarios-list",
        });
      },

      // Funcion para seleccionar tipo de usuario
      handleToggleSwitch() {
        if (this.toggle_switch_btn) {
          this.toggle_switch_btn = false;
          this.userForm.privilegio = this.privilegesArr[2].id_tipo;
        } else {
          this.toggle_switch_btn = true;
          this.userForm.privilegio = this.privilegesArr[1].id_tipo;
        }
      },

      closeCalendar() {
        if (this.calendarType != "") {
          this.calendarType = "";
        }
      },
      cleanInputs() {
        this.userForm = {};
      },
      openQuestion() {
        this.open_question_modal = true;
      },
      closeStatus() {
        this.open_modal_status = false;
      },

      toastIncompleteInputFields() {
        this.$toast.open({
          message: "Por favor completa los campos obligatorios.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },
      toastGenericError() {
        this.$toast.open({
          message: "Error obteniendo información. Por favor recarga la página e intenta nuevamente.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },
      toastPhoneNumberInvalid() {
        this.$toast.open({
          message: "El número de télefono ingresado no es valido.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },
      toastRutUnavailable() {
        this.$toast.open({
          message: "El RUT ingresado está registrado con otro usuario.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },
      toastEmailUnavailable() {
        this.$toast.open({
          message: "El correo ingresado está registrado con otro usuario.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },
      toastUserCreateSuccess() {
        this.$toast.open({
          message: "Usuario agregado exitosamente.",
          type: "success",
          duration: 5000,
          position: "top-right",
        });
      },
      toastUserEditSuccess() {
        this.$toast.open({
          message: "Usuario editado con éxito.",
          type: "success",
          duration: 5000,
          position: "top-right",
        });
      },
      toastUserEditError() {
        this.$toast.open({
          message: "Error al editar usuario. Por favor intenta nuevamente.",
          type: "error",
          duration: 5000,
          position: "top-right",
        });
      },


    },
  };
</script>
